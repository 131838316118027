body {
  margin: 0;
  /* -webkit-font-smoothing: antialiased; */
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100%;
}

* {
  margin: 0px;
  padding: 0px;
  outline: 0px;
}
@font-face {
  font-family: 'Lato';
  src: url('/fonts/Lato/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Home';
  src: url('/fonts/Lato/lato-heavy-italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Heavy';
  src: url('/fonts/Lato/lato-heavy.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Regular';
  src: url('/fonts/Lato/Lato-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato Black';
  src: url('/fonts/Lato/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Bold';
  src: url('/fonts/Lato/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Italic';
  src: url('/fonts/Lato/Lato-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Medium';
  src: url('/fonts/Lato/lato-medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Black Italic';
  src: url('/fonts/Lato/Lato-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Black Original';
  src: url('/fonts/Lato/Lato-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato SemiBold';
  src: url('/fonts/Lato/lato-semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Bold';
  src: url('/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}

button,
i,
svg {
  cursor: pointer;
}

span {
  margin: 0;
}

.pointer {
  cursor: pointer;
}

form {
  margin: 0;
  padding: 0;
}

/* GOOGLE AUTOCOMPLETE */

/* --------------- */
.pac-container:after {
  content: none !important;
}
.pac-container {
  border-radius: 5px;
}

.pac-container .pac-icon {
  width: 14px;
  height: 15px;
  margin-right: 7px;
  margin-left: 7px;
  margin-top: 6px;
  background-image: url('/map-marker-icon.png');
  background-size: 80%;
  background-repeat: no-repeat;
}

.pac-container .pac-icon-marker {
  background-position: center;
}

.pac-container .pac-item {
  padding: 4px 20px;
  color: #1d1d1b;
  border-top: none;
  font-size: 16px;
  font-family: 'Lato Bold';
}

.pac-container .pac-item:hover {
  color: #50b275;
  background: #e6ffef;
}

.pac-container .pac-item-query {
  color: #1d1d1b;
  font-family: 'Lato Bold';
  font-size: 16px;
}

.pac-container .pac-item-query .pac-matched {
  font-family: 'Lato Bold';
}

.pac-container .pac-item:hover .pac-item-query {
  color: #50b275 !important;
}

.pac-container .pac-item-selected {
  color: #50b275 !important;
  background: #e6ffef !important;
}

.pac-container .pac-item-selected .pac-item-query {
  color: #50b275 !important;
}
/* ---------------------- */

.navlink {
  text-decoration: none;
}

.flex-page {
  display: flex;
  justify-content: center;
}

.fullWidth {
  width: 100%;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rows-align {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-between-no-center {
  display: flex;
  justify-content: space-between;
}

.mg-0 {
  margin: 0;
}

.mg-tb-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mg-top-15 {
  margin-top: 15px;
}

.mg-top-10 {
  margin-top: 10px;
}

.mg-top-20 {
  margin-top: 20px;
}

.mg-top-30 {
  margin-top: 30px;
}

.mg-top-40 {
  margin-top: 40px;
}

.mg-top-50 {
  margin-top: 60px;
}

.mg-top-bottom-40 {
  margin: 40px 0 40px 0;
}

.mg-top-bottom-16 {
  margin: 16px 0px;
}

.mg-bottom-16 {
  margin-bottom: 16px;
}

.mg-top-bottom-20 {
  margin: 20px 0 20px 0;
}

.mg-bottom-20 {
  margin-bottom: 20px;
}

.mg-bottom-40 {
  margin-bottom: 40px;
}

.mg-bottom-30 {
  margin-bottom: 30px;
}

.mg-bottom-35 {
  margin-bottom: 35px;
}

.mg-bottom-50 {
  margin-bottom: 50px;
}

.mg-bottom-55 {
  margin-bottom: 55px;
}

.mg-left-20 {
  margin-left: 20px;
}

.mg-left-21 {
  margin-left: 21px;
}

.mg-left-8 {
  margin-left: 8px;
}

.mg-right-8 {
  margin-right: 8px;
}

.mg-left-10 {
  margin-left: 10px;
}

.mg-right-10 {
  margin-right: 10px;
}

.mg-left-15 {
  margin-left: 15px;
}

.mg-left-30 {
  margin-left: 30px;
}

.mg-right-20 {
  margin-right: 20px;
}

.mg-right-30 {
  margin-right: 30px;
}

.mg-right-65 {
  margin-right: 65px;
}

.mg-right-45 {
  margin-right: 55px;
}

.mg-right-40 {
  margin-right: 40px;
}

.mg-right-40 {
  margin-right: 55px;
}

.mg-left-40 {
  margin-left: 40px;
}

.mg-right-55 {
  margin-right: 55px;
}

.mg-right-50 {
  margin-right: 55px;
}

.mg-rl-40 {
  margin: 0px 40px;
}

.pd-right-15-10 {
  padding-right: 15px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
}

.pd-2 {
  padding: 2px;
}

.pd-top-2 {
  padding-top: 2px;
}

.pd-bottom-top-2 {
  padding-bottom: 2px;
  padding-top: 2px;
}

.pd-left-right {
  padding: 0px 20px;
}

.pd-right-2 {
  padding-right: 2px;
}

.imgView {
  object-fit: cover;
}

.border-5 {
  border-radius: 5;
  padding: 2px;
}

.rows {
  display: flex;
  flex-direction: row;
}

.rows-space {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rowsPlan {
  display: flex;
  align-items: center;
}

.flex-page {
  display: flex;
  justify-content: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.rowsJ {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rowsJSpace {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.borderTopRigth {
  border-top-right-radius: 5px;
}

.borderBottomRigth {
  border-bottom-right-radius: 5px;
}

.centerItens {
  display: flex;
  justify-content: center;
}

.centerAll {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.spacebt {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .bottomPage {
    display: none;
  }

  .flex-pageCard {
    display: flex;
    justify-content: center;
  }

  .mobileMenus {
    display: none;
  }

  .centerMobile {
    display: flex;
    justify-content: center;
  }

  .mg-20-top-mobile {
    margin-top: 20px;
  }

  /* .viewMenu{
    display: flex;
    justify-content: center;
    padding: 10px 16px;
    box-sizing: border-box;
  } */
}

@media only screen and (min-width: 992px) {
  .topPage {
    display: none;
  }
}

@media (min-width: 681px) {
  .searchView {
  }
}

.iconBblack:active {
  background: #f2f2ed;
  color: #50b275;
  padding: 17px;
  border-radius: 100%;
}
.lato {
  font-family: Lato Regular;
}

button {
  cursor: pointer;
}

.grecaptcha-badge {
  display: none !important;
}

/* --------- */
#mobile__slider__preview {
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 601px) and (max-width: 991px) {
  #mobile__slider__preview {
    height: 320px;
  }
}

@media only screen and (max-width: 600px) {
  #mobile__slider__preview {
    height: 280px;
  }
}

hr {
  border: 1px solid var(--unnamed-color-d9d9d4);
  border: 1px solid #d9d9d4;
}

/* ------------------------- */
/* MENU HOME */

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: #1d1d1b;
  width: 280px;
  height: 100vh;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  display: flex;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-menu-items {
  margin-left: 20px;
  margin-bottom: 70px;
}

.logoExit {
  margin-top: 30px;
  padding: 0px 20px 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.fa-2x {
  font-size: 24px;
}

@media (min-width: 921px) {
  .nav-menu {
    display: none;
  }
}

@media (max-width: 1120px) {
  .nav-itens-news {
    display: none;
  }
}

.chooseMobile {
  font-size: 18px;
  letter-spacing: 1.8px;
  color: white;
  text-transform: uppercase;
}

.chooseMobileBold {
  font-size: 18px;
  letter-spacing: 1.8px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
}

.nav {
  position: fixed !important;
}

/* ----------------- */

@media only screen and (max-width: 991px) {
  .visible {
    display: none;
  }
  .paddingMobile {
    padding: '0px 30px';
  }
}

@media only screen and (min-width: 992px) {
  .mobile {
    display: none;
  }

  #visiblePrice {
    display: none !important;
  }
}

/* ----------------- */
/* ANUNCIO */

.container__max__skeleton {
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
}

.secondBox {
  display: flex;
  justify-content: flex-end;
  padding: 30px 0px 0px 0px;
}

.colorItem {
  color: #50b376;
}

.fontItem {
  font-size: 14px;
  margin-top: 10px;
  font-family: Lato;
}

.fontItemText {
  font-size: 14px;
  color: #1d1d1b;
  font-family: Lato;
}

.subTitleShow {
  font-size: 24px;
  font-family: Lato Italic;
  font-weight: bold;
  color: #1d1d1b;
}

.iconsFontes {
  font-family: Lato Medium;
  font-size: 14px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 992px) {
  .iconsFontes {
    font-size: 16px;
  }
}

.slickrel > .slick-list > .slick-track > .slick-slide {
  display: flex !important;
  justify-content: center !important;
}

.mobileNumber {
}
.mobileNumber img {
}

.page-container-padding {
  padding: 0 56px;
}

@media (max-width: 600px) {
  .page-container-padding {
    padding: 0 30px;
  }
}

.c-pointer {
  cursor: pointer;
}
.hover-image-filter img {
  transition: all 0.2s ease;
}
.hover-image-filter:hover img {
  filter: brightness(110%);
}

.relative-box {
  position: relative;
}

.relative-box > div {
}

/* --------------------- */
/* Preview */
.container__mw_preview {
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
}

/* --------------------- */
/* Profile */
.iconClose {
  color: #50b275;
  cursor: pointer;
}

.iconClose:hover {
  opacity: 0.5;
}

.divMargin {
  margin-top: 20px;
}

.load {
  padding-top: 6%;
  align-items: center;
}

.btnload {
  display: flex;
  text-align: center;
  justify-items: center;
  align-items: center;
  line-height: center;
  justify-content: center;
  align-content: center;
}

/* ------------------- */
/* Menu Alter */
.green {
  border: none;
  border-bottom: 2px solid #50b275 !important;
}

.red {
  border: none;
  border-bottom: 2px solid #f56662 !important;
}

.redEmail {
  color: #f56662 !important;
}

.greenEmail {
  color: #50b275 !important;
}

.MuiDialog-paper {
  width: 800px;
}

@media (max-width: 663.95px) {
  .MuiDialog-paperWidthSm.MuiDialog-paperScrollBody {
    max-width: calc(96%) !important;
  }

  .MuiDialog-paper {
    margin: 0px !important;
  }
}

/* ------------------- */
/* List ads */
.container__max__top-30 {
  max-width: 1366px;
  width: 100%;
  margin: 30px auto 0;
}

.MuiGrid-spacing-xs-4 > .MuiGrid-item {
  padding: 0;
}

.classCarousel {
  height: 100%;
}

.iconsearch {
  fill: red;
}

@media only screen and (max-width: 600px) {
  .photoSize {
    height: 179px;
  }

  .slick-dots {
    display: none !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .photoSize {
    height: 264px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .photoSize {
    height: 264px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .photoSize {
    height: 264px;
  }
}

@media only screen and (min-width: 1200px) {
  .photoSize {
  }
}

.flex-end-price {
  display: flex;
  align-items: flex-end;
}

.slick-next {
  right: 10px !important;
  border-radius: 100% !important;
  z-index: 1;
}

.slick-prev {
  left: 10px !important;
  border-radius: 100% !important;
  z-index: 1;
}

.slick-prev:before,
.slick-next:before {
  content: '' !important;
  display: none !important;
  color: #1d1d1b;
}

@media only screen and (max-width: 991px) {
  .slick-prev:before,
  .slick-next:before {
    display: none;
  }

  .mobileCenter {
    display: flex;
    justify-content: center;
  }

  .slick-prev:before,
  .slick-next:before {
    display: none;
  }

  .slick-next {
    display: none;
    background: transparent !important;
  }
  .slick-next img,
  .slick-prev img {
    display: none;
  }

  .slick-prev {
    display: none;
    background: transparent !important;
  }
}

.radius-circle-hidden {
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}
